import React, { Fragment } from 'react';
import { array } from 'prop-types';
import * as widgets from './AllWidgets';
import * as sections from '@/components/layout/sections/SectionPresets';
import withMapToComponent from '../with-map-to-component/withMapToComponent';
import MpsAd from '@/components/mps/MpsAd';
import { MpsContext } from '@/components/mps/MpsContext';
import { usePageThemeContext } from '@/components/page-theme/PageThemeContext';
import { WidgetContext } from '@/components/widgets/WidgetContext';
import './widgets.scss';

/**
 * @constant MPS_WIDGET_CONTEXT
 * @description cached object to avoid triggering rerenders on provider
 */
const MPS_WIDGET_CONTEXT = {
	isInWidget: true
};

/**
 * @function sectionHasAdWidget
 * @description if any widget in array has an AD widgetType, returns true. Else returns false.
 * @param {Array} [widgets]
 * @returns {Boolean}
 */
const sectionHasAdWidget = (widgets) => {
	for (let i = 0; i < widgets.length; i++) {
		if (widgets?.[i]?.widgetType === 'AD') return true;
	}

	return false;
};

/**
 * @function checkHasAdjacentAdWidget
 * @param {Array} Object.prevWidgets
 * @param {Array} Object.widgets
 * @returns {Boolean}
 */
export const checkHasAdjacentAdWidget = ({ prevWidgets, widgets }) =>
	sectionHasAdWidget(prevWidgets) || sectionHasAdWidget(widgets);

/**
 * @function MobileMidbanner
 * @returns {React.ReactElement}
 */
const MobileMidbanner = () => (
	<MpsAd
		slot="midbanner"
		className="mps-midbanner-mobile"
		centered
		isMobileOnly={true}
		repeatable
	/>
);

MobileMidbanner.displayName = 'MobileMidbanner';

/**
 * @function TabletDesktopMidbanner
 * @returns {React.ReactElement}
 */
const TabletDesktopMidbanner = () => (
	<MpsAd
		slot="midbanner"
		className="mps-midbanner-tablet-desktop"
		centered
		isHiddenMobile={true}
		repeatable
	/>
);

TabletDesktopMidbanner.displayName = 'TabletDesktopMidbanner';

/**
 * @function Widget
 * @description Maps the props.type to the Widget and returns it
 * @param {Object} props - The props
 * @param {Object} props.type - The name of the widget type
 * @return {Function} The requested Widget Component
 */
const Widget = withMapToComponent(widgets);
Widget.displayName = 'Widget';

/**
 * @function Section
 * @description Maps the props.type to the section layout and returns it
 * @param {Object} props - The props
 * @param {Object} props.type - The name of the section type
 * @return {Function} The requested Section Component based on "type"
 */
const Section = withMapToComponent(sections);
Section.displayName = 'Section';

/**
 * @typedef SectionProps
 * @type {Object}
 * @property {Number} id
 * @property {String} type The layout section type ex. HALVES, THREE_SIX_THREE etc.
 * @property {Array} widgets The widgets per section
 */

/**
 * @function Area
 * @description Creates an area of widgets
 * @param {Object} props
 * @param {Array} props.sections - An array of layout sections (rows)
 * @returns {React.ReactElement} An area based on the requested layout and widgets
 */
const Area = ({ sections }) => {
	// get page theme override
	const { pageTheme } = usePageThemeContext();

	return sections.length ? (
		<Fragment>
			<section className="area">
				{sections.map(({ id, sectionType, widgets }, sectionIndex) => {
					const hasAdjacentAdWidget = checkHasAdjacentAdWidget({
						prevWidgets: sections[sectionIndex - 1]?.widgets || [],
						widgets
					});

					/*
					 * Tablet and Desktop views show an ad after every two widget sections,
					 * but not after the last widget section.
					 */
					const hasTabletDesktopMidbanner =
						sectionIndex !== 0 && !hasAdjacentAdWidget && sectionIndex % 2 === 0;

					/*
					 * Mobile view shows an ad after the first widget section, and then after every two
					 * subsequent widget sections, but not after the last widget section
					 */
					const hasMobileMidbanner =
						sectionIndex === 1 ||
						(sectionIndex !== 0 &&
							!hasAdjacentAdWidget &&
							(sectionIndex - 1) % 2 === 0);

					return (
						<Fragment key={id}>
							{hasTabletDesktopMidbanner && <TabletDesktopMidbanner />}
							{hasMobileMidbanner && <MobileMidbanner />}
							<MpsContext.Provider value={MPS_WIDGET_CONTEXT}>
								<Section type={sectionType}>
									{widgets.map((widget, widgetIndex) =>
										widget?.id && widget?.widgetTemplate && widget?.criteria ? (
											<WidgetContext.Provider
												key={`widget-context-${widget.id}`}
												value={{
													isThumbLazyLoadDisabled:
														widgetIndex === 0 && sectionIndex === 0
												}}
											>
												<Widget
													key={widget.id}
													type={widget.widgetType}
													{...widget}
													position={`sec${sectionIndex + 1}-wid${
														widgetIndex + 1
													}`}
													sectionIndex={sectionIndex}
													sectionType={sectionType}
													pageTheme={pageTheme}
												/>
											</WidgetContext.Provider>
										) : null
									)}
								</Section>
							</MpsContext.Provider>
						</Fragment>
					);
				})}
			</section>
		</Fragment>
	) : null;
};

Area.defaultProps = {
	sections: []
};

Area.propTypes = {
	sections: array.isRequired
};

Area.displayName = 'Area';

export default Area;
